import React from 'react'
import Layout from '../components/layout'
import '../assets/scss/mdb.scss'
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBContainer,
  // MDBCardGroup,
  MDBCardTitle,
  MDBCardText,
  MDBCardFooter,
  // MDBMask,
  // MDBView,
  MDBBtn,
} from 'mdbreact'
import Section6 from '../sections/steps'
// import pb_image from '../images/pb_phone.png'
import MediaQuery from 'react-responsive'
import { ReactComponent as ReimagineVert } from '../images/re-imagine_vert.svg'
import { ReactComponent as Reimagine } from '../images/re-imagine.svg'
import { ReactComponent as Triangle } from '../images/triangle_of_trust.svg'
import { ReactComponent as TriangleVert } from '../images/triangle_of_trust_vert.svg'
import { ReactComponent as PBPhone } from '../images/pb_phone.svg'

const WorksPage = () => {
  return (
    <Layout>
      <MDBContainer>
      <MediaQuery query="(min-width: 768px)">
        <Reimagine></Reimagine>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <ReimagineVert></ReimagineVert>
      </MediaQuery>
      <MDBContainer>
        <MDBCard className="my-5 px-1 pb-5">
          <MDBCardBody>
            <p className="text-justify  mx-auto mb-5">
              Most call center products focus only on one side of the problem -
              building more tools for agents &amp; their supervisors. But what
              about the customer? And what about actually fixing &amp; improving
              on the underlying phone network itself? A big part of the effort
              it takes to call comes from how limited the existing phone network
              is. It works by connecting phones not by connecting people. There
              no reliable way to know &amp; trust who is on the other end of the
              line &amp; there is no reliable &amp; secure way to exchange
              information.
            </p>
            <hr className="hr-dark" />
            <MDBContainer>
              <MDBRow>
                <MDBCol md="4">
                  <PBPhone></PBPhone>
                  {/* <img src={pb_image} className="img-fluid" alt="" /> */}
                </MDBCol>
                <MDBCol md="8">
                  <h2 className="text-center w-responsive mx-auto mb-5">
                    HearRo uses the Sovrin public blockchain to add three kinds
                    of "cryptographic" trust that enable a whole new way to
                    communicate.
                  </h2>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCardBody>
        </MDBCard>
      </MDBContainer>
      <MediaQuery query="(min-width: 768px)">
        <Triangle></Triangle>
      </MediaQuery>
      <MediaQuery query="(max-width: 767px)">
        <TriangleVert></TriangleVert>
      </MediaQuery>
      <MDBContainer>
        <MDBRow>
          <MDBCol lg="4" sm="12">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle tag="h5" className="orange-text">
                  Connections
                </MDBCardTitle>
                <MDBCardText>
                  The lack of a single, trusted source of identity in existing
                  phone systems means that every new call to a new company
                  requires a new account and a separate authentication.
                  Self-Sovereign Identity (SSI) allows both sides of a call to
                  securely identify, authenticate and setup a persistent
                  connection that can be used over and over. All without having
                  to waste time, each time, proving who you are. HearRo extends
                  and enhances SSI to help easily manage your connections to
                  other people, organizations and things.
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter small muted>
                <pre> </pre>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="4" sm="12">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle tag="h5" className="blue-text">
                  Connversations
                </MDBCardTitle>
                <MDBCardText>
                  Persistent blockchain connections enable easy-to-use, fully
                  digital connversations. Connect and re-connect without ever
                  waiting on hold or being transferred. You control when the
                  conversation starts and, more importantly, when it ends. You
                  never have to start over since you have a permanent shared
                  history of your entire conversation, from first contact to
                  resolution. Seamlessly switch from chat to voice to video at
                  will without losing the context and history of your
                  interaction. Start a call with a bot, switch to an audio call
                  with an agent from your car, continue with a chat during a
                  meeting - all in the same conversation. Share screens, photos,
                  documents and credentials.
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter small muted>
                <pre> </pre>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="4" sm="12">
            <MDBCard>
              <MDBCardBody>
                <MDBCardTitle tag="h5" className="teal-text">
                  Credentials
                </MDBCardTitle>
                <MDBCardText>
                  Access to verifiable credentials means that users are able to
                  share and update their personal information securely with
                  agents, so they never have to spell out their email address,
                  phone or credit card numbers. Credentials also enable new ways
                  of getting work done over the phone. Fill in applications,
                  send authorizations and verify ownership using a 100% digital
                  workflow. Reduce fraud, create compelling customer
                  experiences, and minimize data footprints. By converting
                  today’s physical credentials to their digital equivalents we
                  have finally unleashed their superpowers. It's the digital
                  transformation we've been waiting for!
                </MDBCardText>
              </MDBCardBody>
              <MDBCardFooter small muted>
                <pre> </pre>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <hr className="hr-light" />
      <MDBContainer>
          <MDBRow>
            <MDBCol lg="6" md="12" className="mb-lg-0 mb-4">
              <MDBContainer className="rounded z-depth-2 mb-4">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://player.vimeo.com/video/305420834?title=0&byline=0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                  ></iframe>
                </div>
              </MDBContainer>
            </MDBCol>
            <MDBCol lg="6" md="12" className="mb-lg-0 mb-4">
              <h4 className=" mb-3">What is Sovrin?</h4>
              <p className="dark-grey-text">
                The Sovrin Network is a new standard for digital identity –
                designed to bring the trust, personal control, and ease-of-use
                of analog IDs – like driver’s licenses and ID cards – to the
                Internet. Sovrin based identities are open for any application
                to use, giving people, organizations, and things the freedom to
                prove things about themselves using trustworthy, verifiable
                digital credentials. With Sovrin, the individual identity holder
                controls their credentials, using them whenever and however they
                please, without being forced to request permission of an
                intermediary.
              </p>
              <a
                href="https://sovrin.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MDBBtn color="deep-orange" rounded size="md">
                  Learn more
                </MDBBtn>
              </a>
            </MDBCol>
          </MDBRow>
      </MDBContainer>
      <Section6 />
      </MDBContainer>
    </Layout>
  )
}

export default WorksPage
